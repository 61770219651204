.footerRootContainer {
    padding: 90px 100px 72px 115px;

    @media screen and (max-width: 768px) {
        padding: 43px 34px 36px 45px;
    }
}

.footerEmailButtonContainer {
    @media screen and (max-width: 768px) {
        flex-direction: column;
        row-gap: 16px;
    }
}

.footerImprintContainer {
    padding-top: 100px;

    @media screen and (max-width: 768px) {
        padding-top: 26px;
        justify-content: center;
    }
}

.footerLinksDemoContianer {
    padding-left: 140px;
    display: flex;

    @media screen and (max-width: 768px) {
        display: none;
    }
}

.footer-item {
    &:hover {
    text-decoration: underline;
    text-decoration-thickness: 1px;
    text-decoration-style: solid;
    text-underline-offset: 5px;
    text-decoration-color: #6925d8;
    }
}

.footer-btn {
    width: auto;
    font-size: 16px;
    @media screen and (max-width: 768px) {
        width: 100%;
    }
}
