.advContainer {
    padding: 61px 86px 100px 86px;

    @media screen and (max-width: 768px) {
        padding: 32px 42px 41px 46px
    }
}

.advTitleSpan {
    font-size: 42px;
    line-height: 52px;
    text-align: center;

    @media screen and (max-width: 768px) {
        font-size: 32px;
        line-height: normal;
        text-align: start;
    }
}

.advSectionRootContainer {
    flex-direction: row;
    padding-top: 56px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        padding-top: 11px;
        text-align: center;
    }
}