.header-wrapper {
  border: 1px solid transparent;
  position: fixed;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #1F8EA5;
  align-items: 'center';
  box-shadow: 0 12px 24px 0 rgb(20 20 43 / 8%);
}

.card_css {
  backdrop-filter: blur(16px) saturate(180%);
  -webkit-backdrop-filter: blur(16px) saturate(180%);
  background-color: rgba(17, 25, 40, 0.75);
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.125);
}

svg {
  fill: #fff;
}

.clr_white {
  color: #fff !important;
}

.mr8 {
  margin-right: 8px;
  color: #ffff;
}

.txt-none {
  text-transform: none;
}

.lng-selector-dd {
  padding: 0px !important;
  color: white !important;
  height: 35px;
  border: 1px solid white;
  width: 130px !important;

  svg {
    fill: #FFFF;
  }
}

.overlay-drawer {
  z-index: 100;
  position: absolute;
  visibility: visible;
}

.sidebar-layout-wrapper {
  margin-top: 0px;
  .menu-icons {
    color: white;
  }

  .dashboard-wrapper {
    max-width: auto;
  }

  .list-drawer>div {
    border: none;
    background-color: #1F8EA5;
  }

  .number-wrapper {
    justify-content: space-between;

    .MuiCard-root {
      flex-basis: calc(21% - 24px);
      box-shadow: none;
    }
  }
  .MuiSelect-icon {
    color: white;
  }
  .MuiButtonBase-root {
    box-shadow: none;
  }

  .MuiButtonBase-root:hover {
    box-shadow: none;
  }
}
