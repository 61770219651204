/* Paddings */
.gp-p4 {
  padding: 4px;
}

.gp-p8 {
  padding: 8px;
}

.gp-p12 {
  padding: 12px;
}

.gp-p16 {
  padding: 16px;
}

.gp-p20 {
  padding: 20px;
}

.gp-p24 {
  padding: 24px;
}

.gp-p32 {
  padding: 32px;
}

.gp-p36 {
  padding: 36px;
}

.gp-p40 {
  padding: 40px;
}

.gp-p70 {
  padding: 70px;
}

.gp-pt4 {
  padding-top: 4px;
}

.gp-pt8 {
  padding-top: 8px;
}

.gp-pt10 {
  padding-top: 10px;
}

.gp-pt12 {
  padding-top: 12px;
}

.gp-pt16 {
  padding-top: 16px;
}

.gp-pt20 {
  padding-top: 20px;
}

.gp-pt22 {
  padding-top: 22px;
}

.gp-pt24 {
  padding-top: 24px;
}

.gp-pt32 {
  padding-top: 32px;
}

.gp-pt36 {
  padding-top: 36px;
}

.gp-pt40 {
  padding-top: 40px;
}

.gp-pb4 {
  padding-bottom: 4px;
}

.gp-pb8 {
  padding-bottom: 8px;
}

.gp-pb12 {
  padding-bottom: 12px;
}

.gp-pb16 {
  padding-bottom: 16px;
}

.gp-pb20 {
  padding-bottom: 20px;
}

.gp-pb24 {
  padding-bottom: 24px;
}

.gp-pb32 {
  padding-bottom: 32px;
}

.gp-pb36 {
  padding-bottom: 36px;
}

.gp-pb40 {
  padding-bottom: 40px;
}

.gp-pr4 {
  padding-right: 4px;
}

.gp-pr8 {
  padding-right: 8px;
}

.gp-pr10 {
  padding-right: 10px;
}

.gp-pr12 {
  padding-right: 12px;
}

.gp-pr16 {
  padding-right: 16px;
}

.gp-pr20 {
  padding-right: 20px;
}

.gp-pr24 {
  padding-right: 24px;
}

.gp-pr32 {
  padding-right: 32px;
}

.gp-pr36 {
  padding-right: 36px;
}

.gp-pl4 {
  padding-left: 4px;
}

.gp-pl8 {
  padding-left: 8px;
}

.gp-pl12 {
  padding-left: 12px;
}

.gp-pl16 {
  padding-left: 16px;
}

.gp-pl20 {
  padding-left: 20px;
}

.gp-pl24 {
  padding-left: 24px;
}

.gp-pl32 {
  padding-left: 32px;
}

.gp-pl36 {
  padding-left: 36px;
}

.gp-pl60 {
  padding-left: 60px;
}

.gp-pb66 {
  padding-bottom: 66px;
}

.gp-pt64 {
  padding-top: 64px;
}

.gp-pl198 {
  padding-left: 198px;
}

.gp-pl77 {
  padding-left: 77px;
}

.pr-236 {
  padding-right: 236px;
}

.gp-pb66 {
  padding-bottom: 66px;
}

.gp-pt64 {
  padding-top: 64px;
}

.gp-pl198 {
  padding-left: 198px;
}

.gp-pl77 {
  padding-left: 77px;
}

.pr-236 {
  padding-right: 236px;
}

.pt-155 {
  padding-top: 155px;
}

.pt-56 {
  padding-top: 56px;
}

.pt-21 {
  padding-top: 21px;
}