:root {
  ---theme-color: #1dc5e5;
  ---sidebar-bg-color: linear-gradient(180.08deg,
      #13567a 10.72%,
      #62cddc 99.93%);
  ---normal-font-size: 1rem;
  ---small-font-size: 0.875rem;
  ---z-fixed: 100;
  ---body-font: 'Raleway', sans-serif;
  ---sub-color: #b6cefc;
  ---white-color: #fff;
  ---nav-width: 82px;
  ---sidebar-font-color: #a0a3a4;
  ---sidebar-background-color: rgba(204, 204, 204, 0.1);
  ---background-color: #f9fafd;
  ---gray-font-color: #929db0;
}

body {
  width: 100%;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: 'Raleway', sans-serif;
  background: var(---background-color);
  // overflow: hidden;
  padding: 0;
  margin: 0;
}

.app-container {
  overflow: scroll;
}

.ibt {
  display: inline-block;
  vertical-align: top;
}

.db {
  display: block;
}

.ibm {
  display: inline-block;
  vertical-align: middle;
}

.ibb {
  display: inline-block;
  vertical-align: bottom;
}

.tc {
  text-align: center;
}

.tl {
  text-align: left;
}

.tr {
  text-align: right;
}

.t-decor {
  text-decoration: none;
}

.w-100 {
  width: 100%;
  max-width: 100%;
}

.w-auto {
  width: auto;
}

.h-100 {
  height: 100%;
  max-height: 100%;
}

.p-relative {
  position: relative;
}

.d-flex {
  display: flex;
}

.flex-d-column {
  flex-direction: column;
}

.flex-d-row {
  flex-direction: row;
}

.flex-wrap {
  flex-wrap: wrap;
}

.visible {
  visibility: visible;
}

.hide {
  visibility: hidden;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-around {
  justify-content: space-around;
}

.cursor-hand {
  cursor: pointer;
}

.border-r-50 {
  border-radius: 50%;
}

.border-r {
  border: 2px solid red;
}

.border-b {
  border: 2px solid blue;
}

.error-text {
  color: red;
}

.success-text {
  color: green;
}

.success__label {
  color: #05bb9a;
}

.cp-theme-color {
  color: #6924D8 !important;
}

.warning__label {
  color: rgb(255, 239, 0);
}

.danger__label {
  color: #fca416;
}

.critical__label {
  color: red;
}

.open__label {
  color: #69778e;
}

.theme-icon {
  svg>path {
    fill: var(---theme-color);
  }
}

.action-btn {
  border-radius: 100px !important;
  background-color: #6926D9 !important;
  padding: 17px 28px 17px 30px !important;
  font-family: 'DMSans' !important;
  color: #fff !important;
  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 26px !important;
  text-transform: none !important;
  &:hover {
      background-color: #8047df !important;
    }
}

.white-loader,
.blue-loader {
  color: #ffffff;
  font-size: 30px;
  animation: spin 1000ms linear infinite;
  -webkit-animation: spin 1000ms linear infinite;
}

.blue-loader {
  color: var(---theme-color);
  font-size: 16px;
}

.blue-cta,
.green-cta,
.danger-cta,
.deleted-cta,
.green-border-cta,
.border-cta {
  border: 1px solid transparent;
  color: #ffff;
  background: var(---theme-color);
  width: max-content;
  padding: 8px 18px;
  border-radius: 7px;
  cursor: pointer;
  text-align: center;
  max-height: 40px;
}

.green-cta {
  background-color: #05bb9a;

  &:hover {
    color: #05bb9a;
    border: 1px solid #05bb9a;
    background-color: #ffffff;
  }
}

.danger-cta {
  color: #e44817;
  border: 1px solid #e44817;
  background-color: #ffffff;

  &:hover {
    background-color: #e44817;
    color: #ffffff;
  }
}

.green-border-cta {
  color: #05bb9a;
  border: 1px solid #05bb9a;
  background-color: #ffffff;

  &:hover {
    background-color: #05bb9a;
    color: #ffffff;
  }
}

.border-cta {
  background-color: transparent;
  color: var(---theme-color);
  border: 1px solid;

  &:hover {
    color: #ffffff;
    border: 1px solid var(---theme-color);
    background-color: var(---theme-color);
  }
}

.deleted-cta {
  background-color: #808080;
  cursor: not-allowed;
}

.blue-cta:hover {
  color: var(---theme-color);
  border: 1px solid var(---theme-color);
  background-color: #ffffff;
}

.icon-btn {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
}

.icon-message {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}

.menu-nav {
  display: flex;
  justify-content: space-between;
}

.dropdown {
  position: absolute;
  right: 10px;
  outline: none;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgb(205 213 225 / 40%);
  border-radius: 10px;
  opacity: 0;
  top: -40px;
  padding: 10px;
  width: max-content;
  z-index: -1;
  max-height: 0;
}

.dropdown-container:focus {
  outline: none;
}

.dropdown-container:focus .dropdown {
  opacity: 1;
  z-index: 100;
  max-height: 100vh;
}

.inactive-section {
  opacity: 0.5;
  pointer-events: none;
}

.success-label,
.warning-label,
.yellow-label,
.open-label,
.danger-label,
.na-label {
  padding: 6px 20px;
  border-radius: 4px;
  width: max-content;
}

.success-label,
.green-dot-group {
  color: #05bb9a;
  background: #f2fefc;
}

.warning-label,
.amber-dot-group {
  color: #fca416;
  background: #fff8ec;
}

.danger-label,
.danger-dot-group,
.red-dot-group {
  color: #e44817;
  background-color: #fff5f1;
}

.yellow-label,
.yellow-dot-group {
  color: grey;
  background-color: #fdffeb;
}

.open-label {
  color: #444;
  background-color: #ecede6;
}

.na-label {
  color: #ffff;
  background-color: #788aaa;
}

.countdown-timer {
  color: var(---theme-color);
  font-size: 18px;
  border-radius: 50%;
  border: 1px dashed var(---theme-color);
  padding: 3px 10px;
}

.table-wrapper {
  height: calc(100vh - 185px);
}

.blue-chip,
.light-blue-chip,
.grey-chip {
  background: var(---theme-color);
  padding: 6px 12px;
  color: #ffffff;
  font-size: 12px;
  border-radius: 5px;
  width: max-content;
  height: max-content;
}

.light-blue-chip {
  background-color: #f3f8ff;
  color: var(---theme-color);
}

.grey-chip {
  background: #e3e8f1;
  color: #001233;
}

.cp {
  cursor: pointer;
}

.no-item-found-bg {
  background-image: url(https://d11jcn2a09nppp.cloudfront.net/website/static-images/platform/no-data-found.jpg);
  background-repeat: no-repeat;
  min-height: 200px;
  height: auto;
  background-size: contain;
  width: 100%;
}

select {
  width: 100%;
  border: none;
  outline: none;
  font-family: 'Raleway';
  background-color: transparent;
}

select:valid {
  color: #929db0;
}

.list-wrapper {
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.expanded-menu {
  margin-left: 14rem;
  width: calc(100% - 14.5rem);
}

.shrinked-menu {
  margin-left: 5rem;
  width: calc(100% - 5rem);
}

.common-textarea {
  width: 100%;
  min-height: 80px;
  border: 1px solid #e3e8f1;
  outline: none;
  resize: none;
  padding: 6px 12px;
  font-family: 'Raleway';
  font-size: 12px;
  color: #001233;
  line-height: 170%;
  border-radius: 10px;
}

.counter-placeholder {
  background: #e3e8f1;
  color: #ffffff;
  height: 28px;
  margin-left: -4px;
  width: 28px;
  border-radius: 50%;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.transform-to-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.dotted-text {
  display: inline-block;
  width: 320px;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.hidden-div {
  position: absolute;
  height: 40px;
  width: 42px;
  background: white;
  right: 11.5px;
  top: 12px;
}

.common-input,
.select-wrapper {
  border: 1px solid #e3e8f1;
  border-radius: 10px;
  height: 40px;
  width: 100%;
  outline: none;
  padding: 10px;
  color: var(---gray-font-color);
}

.common-input::placeholder {
  color: var(---gray-font-color);
  font-size: 12px;
}

.attached-doc-wrapper {
  border: 0.2px solid rgb(242, 239, 239);
  border-radius: 8px;
  padding: 4px;
}

.select-wrapper {
  padding: 6px;
}

.loading {
  left: 50%;
  top: 50%;
  font-size: 28px;
  font-family: serif;
  font-weight: bold;
  letter-spacing: 4.4px;
  text-transform: capitalize;
  position: absolute;
  overflow: hidden;
  transform: translate(-50%, -60%);

  &:before {
    color: #aaa;
    content: attr(data-loading-text);
  }

  &:after {
    top: 0;
    left: 0;
    width: 0;
    opacity: 1;
    color: #444;
    overflow: hidden;
    position: absolute;
    content: attr(data-loading-text);
    animation: loading 5s infinite;
  }
}

.green-dot-group,
.yellow-dot-group,
.amber-dot-group,
.danger-dot-group,
.red-dot-group {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  justify-content: center;
  font-size: 12px;
}

.admin-label {
  background: #f5f7ff;
  color: #4354ee;
}

.owner-label {
  color: #05bb9a;
  background: #f2fefc;
}

.view-only-label {
  color: #fca416;
  background: #fff8ec;
}

.no-access-label {
  color: #e44817;
  background-color: #fff5f1;
}

.admin-label,
.owner-label,
.employee-label,
.view-only-label,
.master-label {
  border-radius: 5px;
}

.loading-dots:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4, end) 900ms infinite;
  animation: ellipsis steps(4, end) 900ms infinite;
  content: '\2026';
  width: 0px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.block-span {
  display: block;
}

.pointer-events-none {
  pointer-events: none;
}

.required:after {
  content: ' *';
  color: red;
}

.select-without-arrow {
  appearance: none;
}

.border-dropdown {
  border: 1px solid var(---theme-color) !important;
}

.hover-card:hover {
  transition: all 0.2s ease-out;
  transform: scale(1.04);
  box-shadow: 0 12px 24px 0 rgb(20 20 43 / 8%);
  cursor: pointer;
}

.no-hover:hover {
  transition: none;
  transform: none;
  box-shadow: none;
}

.hoverable-row:hover {
  transition: all 0.2s ease-out;
  box-shadow: 0 12px 24px 0 rgb(20 20 43 / 8%);
}

a {
  text-decoration: none;
  color: var(---theme-color);
}

.search-container-width {
  width: 250px;
}

::-webkit-scrollbar {
  width: 0;
  // width: 0.2em;
}

::-webkit-scrollbar-thumb {
  background: #eee;
}

.count-placeholder {
  background: var(---theme-color);
  color: #ffffff;
  height: 25px;
  margin-left: -4px;
  width: 25px;
  border-radius: 50%;
  font-size: 14px;
}

.break-word {
  word-break: break-word;
}

.creation-container {
  width: max-content;
  padding: 6px 40px 6px 12px;
  border: 1px solid #e3e8f1;
  border-radius: 5px;
}

.highlighted-text:hover {
  color: var(---theme-color);
  cursor: pointer;
}

.hidden {
  display: none;
}

.w-70 {
  width: 70px;
}

.h-70 {
  height: 70px;
}

.h-full {
  height: 100%;
}

.bg-cover {
  background-size: cover;
}

.w-full {
  width: 100%;
}

.gap-x-28 {
  column-gap: 28px;
}

.gap-x-32 {
  column-gap: 32px;
}

.items-center {
  align-items: center;
}

.rounded-100 {
  border-radius: 100px;
}

.max-w-830 {
  max-width: 830px;
}

.max-w-513 {
  max-width: 513px;
}

.gap-x-10 {
  column-gap: 10px;
}

.gap-y-22 {
  row-gap: 22px;
}

.gap-y-8 {
  row-gap: 8px;
}

.gap-y-13 {
  row-gap: 13px;
}

.d-grid {
  display: grid;
}

.opacity-30 {
  opacity: 0.3;
}

.items-start {
  align-items: start;
}

.rounded-10 {
  border-radius: 10px;
}

.self-start {
  align-self: flex-start;
}

.border-box {
  box-sizing: border-box;
}

.justify-end {
  justify-content: flex-end;
}

.self-center {
  align-self: center;
  height: 100%;
}

.bg-cover {
  background-size: cover;
}

.w-full {
  width: 100%;
}

.gap-x-28 {
  column-gap: 28px;
}

.gap-x-32 {
  column-gap: 32px;
}

.items-center {
  align-items: center;
}

.items-center, ul {
  margin-bottom: 0rem !important;
}

.rounded-100 {
  border-radius: 100px;
}

.max-w-830 {
  max-width: 830px;
}

.max-w-513 {
  max-width: 513px;
}

.gap-x-10 {
  column-gap: 10px;
}

.gap-y-22 {
  row-gap: 22px;
}

.gap-y-8 {
  row-gap: 8px;
}

.gap-y-13 {
  row-gap: 13px;
}

.d-grid {
  display: grid;
}

.opacity-30 {
  opacity: 0.3;
}

.items-start {
  align-items: start;
}

.rounded-10 {
  border-radius: 10px;
}

.self-start {
  align-self: flex-start;
}

.border-box {
  box-sizing: border-box;
}

.justify-end {
  justify-content: flex-end;
}

.self-center {
  align-self: center;
}