.simpleRootContainer {
  @media screen and (max-width: 768px) {
    padding: 45px 0px 0px 20px;
  }
}

.simpleTransContainer {
  padding: 80px 24px 20px 50px;

  @media screen and (max-width: 768px) {
    padding: 80px 24px 20px 0px;
  }
}

.simpleTitleSpan {
  font-size: 40px;

  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
}

.maskImg {
  display: flex;
  object-fit: cover;
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.maskMobileContainer {
  display: none;
  margin-top: 40px;
  justify-content: flex-end;

  @media screen and (max-width: 768px) {
    display: flex;
  }
}

.pointContainer {
  @media screen and (max-width: 768px) {
    max-width: 286px;
  }
}

.register-now-btn {
  margin: 23px 0 20px 0 !important;
  float: left !important;
  clear: both !important;
  width: 300px !important;
}
