.bannerContainer {
    padding: 45px 215px 45px 215px;
    background-color: #6926D9;

    @media screen and (max-width: 768px) {
        padding: 43px 69px 36px 35px;
    }
}

.bannerSubContainer {
    flex-direction: row;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        row-gap: 25px;
    }
}
