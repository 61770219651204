.productFeaturesRoot {
  padding-top: 60px;
  background-color: #270c68;
  height: auto;
  color: #fff;

  @media screen and (max-width: 768px) {
    padding: 60px 20px 20px 20px;
    height: auto;
  }
}

.card-content {
  color: #fff;
  padding-top: 10px;
  max-width: 354px;
  opacity: 70%;
  text-wrap: wrap;
  text-align: left;
}

.productFeaturesTitleSpan {
  font-size: 42px;
  padding-top: 24px;

  @media screen and (max-width: 768px) {
    font-size: 32px;
  }
}

.productFeaturesCardContainer {
  padding-top: 68px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    // padding: 43px 20px;
  }
}

.accordionRootContainer {
  padding: 100px 115px 100px 200px;

  @media screen and (max-width: 768px) {
    padding: 50px 34px 56px 45px;
  }
}

.accordionSubContainer {

  @media screen and (max-width: 768px) {
    flex-direction: column;
    row-gap: 58px;
  }
}

.pf-subtitle {
  padding-top: 30px;
  max-width: 891px;
  opacity: 70%;
}

.gp-30 {
  gap: 30px;
}

.bb {
  box-sizing: border-box;
}

.mw-1300 {
  max-width: 1300px;
}

.mac-analytics-cont {
  position: relative;
  margin-top: 70px;
  text-align: center;
  .macbook-img {
      width: 90%;
      margin-bottom: -4px;
    @media screen and (max-width: 768px) {
      width: 420px;
      margin-top: 0px;
      margin-bottom: -24px;
    }
  }
  .analytics-img {
    position: absolute;
    left: 138px;
    top: 30px;
    right: 0;
    bottom: 0;
    height: 453px;
    width: 912px;
    @media screen and (max-width: 768px) {
      left: 52px;
      top: 17px;
      right: 0;
      bottom: 0;
      width: 312px;
    }
  }
}
