/* Typography: text-type */

.gp-text0 {
  font-size: 0px;
}

.gp-text10 {
  font-size: 10px;
}

.gp-text11 {
  font-size: 11px;
}

.gp-text12 {
  font-size: 12px;
}

.gp-text13 {
  font-size: 13px;
}

.gp-text14 {
  font-size: 14px;
}

.gp-text16 {
  font-size: 16px;
}

.gp-text18 {
  font-size: 18px;
}

.gp-text20 {
  font-size: 20px;
}

.gp-text22 {
  font-size: 22px;
}

.gp-text24 {
  font-size: 24px;
}

.gp-text26 {
  font-size: 26px;
}

.gp-text28 {
  font-size: 28px;
}

.gp-text32 {
  font-size: 32px;
}

.gp-text36 {
  font-size: 36px;
}

.gp-text40 {
  font-size: 40px;
}

.gp-text09vw {
  font-size: 0.9vw;
}

/* Typography : font-weight */

.gp-f300 {
  font-weight: 300;
  font-family: Raleway;
}

.gp-f400 {
  font-weight: 400;
  font-family: Raleway;
}

.gp-f500 {
  font-weight: 500;
  font-family: Raleway;
}

.gp-f700 {
  font-weight: 700;
  font-family: Raleway;
}

.gp-fn800 {
  font-weight: 800;
}

.gp-f900 {
  font-weight: 900;
  font-family: Raleway;
}

.gp-fbold {
  font-weight: bold;
  font-family: Raleway
}

.gp-fnormal {
  font-weight: normal;
}

/* Typography : letter-spacings */

.gp-ls03 {
  letter-spacing: 0.3px;
}

.gp-ls05 {
  letter-spacing: 0.5px;
}

.gp-ls04 {
  letter-spacing: 0.4px;
}

/* Typography : letter-spacings */

.gp-ls81 {
  letter-spacing: -0.8px;
}

.gp-ls02 {
  letter-spacing: 0.02em;
}

.gp-fsi {
  font-style: italic;
}

.gp-fsn {
  font-style: normal;
}

/* Typography : line-heights */

.gp-lh10 {
  line-height: 1px;
}

.gp-lh11 {
  line-height: 11px;
}

.gp-lh12 {
  line-height: 12px;
}

.gp-lh13 {
  line-height: 13px;
}

.gp-lh14 {
  line-height: 14px;
}

.gp-lh15 {
  line-height: 15px;
}

.gp-lh17 {
  line-height: 17px;
}

.gp-lh19 {
  line-height: 19px;
}

.gp-lh22 {
  line-height: 22px;
}

.gp-lh30 {
  line-height: 30px;
}

.gp-lh38 {
  line-height: 38px;
}

.gp-lh49 {
  line-height: 49px;
}

.gp-lh162 {
  line-height: 162.02%;
}

.gp-lh170 {
  line-height: 170%;
}

.gp-z10 {
  z-index: 10;
}

.gp-z100 {
  z-index: 100;
}

.gp-z999 {
  z-index: 999;
}

@media screen and (max-width: 767px) {
  .gp-mtext0 {
    font-size: 0px;
  }

  .gp-mtext10 {
    font-size: 10px;
  }

  .gp-mtext12 {
    font-size: 12px;
  }

  .gp-mtext13 {
    font-size: 13px;
  }

  .gp-mtext14 {
    font-size: 14px;
  }

  .gp-mtext16 {
    font-size: 16px;
  }

  .gp-mtext18 {
    font-size: 18px;
  }

  .gp-mtext20 {
    font-size: 20px;
  }

  .gp-mtext21 {
    font-size: 21px;
  }

  .gp-mtext24 {
    font-size: 24px;
  }

  .gp-mtext32 {
    font-size: 32px;
  }

  .gp-text40 {
    font-size: 28px;
  }

  .gp-text32 {
    font-size: 24px;
  }

  .gp-mlh12 {
    line-height: 1.2;
  }

  .gp-text26 {
    font-size: 14px;
  }
}

.font-Manrope {
  font-family: 'Manrope' !important;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.leading-26 {
  line-height: 26px;
}

.text-white {
  color: white !important;
}

.font-Inconsolata {
  font-family: 'Inconsolata';
}

.font-weight-700 {
  font-weight: 700;
}

.text-58 {
  font-size: 58px;
}

.leading-70 {
  line-height: 70px;
}

.-tracking-1 {
  letter-spacing: -1px;
}

.text-center {
  text-align: center;
}

.font-Manrope {
  font-family: 'Manrope';
}

.font-Manrope::placeholder {
  font-family: 'Manrope';
}

.leading-28 {
  line-height: 28px;
}

.font-weight-400 {
  font-weight: 400;
}

.leading-26 {
  line-height: 26px;
}

.leading-32 {
  line-height: 32px;
}

.text-42 {
  font-size: 42px;
}

.text-60 {
  font-size: 60px;
}

.leading-52 {
  line-height: 52px;
}

.-tracking-04 {
  letter-spacing: -0.4px;
}

.tracking-0 {
  letter-spacing: 0px;
}

.tracking-2 {
  letter-spacing: 2px;
}

.uppercase {
  text-transform: uppercase;
}

.tdu {
  text-decoration: underline;
}