.rootDiv {
  background-image: url('https://d2lybwojpmsvj1.cloudfront.net/landing-page/helloparts_hero_cropped.png');
  padding-bottom: 60px;

  @media screen and (max-width: 768px) {
    background-image: url('https://d2lybwojpmsvj1.cloudfront.net/landing-page/hero_bg_mobile.png');
    padding-bottom: 100px;
  }
}

.titleDiv {
  padding-top: 118px;

  @media screen and (max-width: 768px) {
    padding-top: 136px;
  }
}


.titleSpan {
  font-size: 58px;
  max-width: 830px;
  line-height: 70px;

  @media screen and (max-width: 768px) {
    font-size: 32px;
    max-width: 390px;
    line-height: normal;
    padding: 0px 20px 0px 20px;
  }
}

.subtitleSpan {
  font-size: 18px;
  max-width: 513px;
  padding-top: 16px;
  letter-spacing: 0.5px;

  @media screen and (max-width: 768px) {
    font-size: 16px;
    max-width: 304px;
    padding-top: 46px;
  }
}

.buttonDiv {
  flex-direction: row;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    row-gap: 22px;
    padding: 0px 20px 0px 20px;
    box-sizing: border-box;
  }
}

.brand-logo-container {
  width: 100%;
  overflow: hidden;
  align-items: center;
  padding-top: 60px;
}

@keyframes ticker-kf {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-420rem);
  }
}

.img-ticker {
  display: flex;
  margin-left: -1rem;
  margin-right: -1rem;
  animation: ticker-kf 120s linear infinite;
  -webkit-animation: ticker-kf 120s linear infinite;
}

.tickerlogo {
  width: 8rem;
  flex: none;
  margin: 0 1rem;
  align-self: flex-start;
  max-width: 100%;
  height: auto;
}
