.header {
  width: 100%;
  padding: 5px 25px;
  // height: 98px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: #fff;

  .menuToggleBtn {
    display: none;
    font-size: 24px;
    position: absolute;
    right: 23px;
    top: 35px;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 25px 0px 0px 25px;
    align-items: flex-start;

    .menuToggleBtn {
      display: block;
    }
  }
}

.nav_logo {
  width: 20%;
  @media screen and (max-width: 768px) {
    width: auto;
  }
}

.hero-btn {
  border-radius: 100px !important;
  text-transform: none !important;
  width: 200px !important;
  padding: 17px 26px !important;
  font-weight: 500 !important;
  font-size: 18px !important;
  @media screen and (max-width: 768px) {
    width: 100% !important;
  }
} 

.get-started {
  color: #fff !important;
  background-color: #6924D8 !important;
  border: none;
  &:hover {
    background-color: #8047df !important;
  }
}

.how-it-works {
  color: #000 !important;
  background-color: #fff !important;
  border: none !important;
  &:hover {
    background-color: #d1c6e2 !important;
  }
}


.w-150 {
  width: 150px;
}

.clr_202020 {
  color: #202020 !important;
}

.loginLink {
  margin-left: auto;
  @media screen and (max-width: 768px) {
    margin-left: 0px;
  }
}

.logoImage {
  height: 46;
  width: 227;

  @media screen and (max-width: 768px) {
    height: 167;
    width: 29;
  }

}