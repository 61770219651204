.alwaysAvailableRootContainer {
    padding: 100px 215px 100px 215px;

    @media screen and (max-width: 768px) {
        padding: 66px 34px 0px 45px;
    }
}

.alwaysAvailableSubContainer {
    flex-direction: row;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        row-gap: 45px;
    }
}

.alwaysAvailableTitleSpan {
    font-size: 42px;
    line-height: 52px;

    @media screen and (max-width: 768px) {
        font-size: 32px;
        line-height: normal;
    }
}

.alwaysAvailableFormContainer {
    margin-top: 10px;

    @media screen and (max-width: 768px) {
        margin: 35px -34px 0px -45px;
    }
}

.alwaysAvailableSubmitContainer {
    @media screen and (max-width: 768px) {
        flex-direction: column;
        row-gap: 35px;
    }
}

.fs_12 {
    font-size: 12px !important;
}

.custom-circular-loader {
    color: #9bc1e6 !important;
    width: 25px !important;
    height: 25px !important;
}
