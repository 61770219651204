/* Margins */
.gp-m4 {
  margin: 4px;
}

.gp-m8 {
  margin: 8px;
}

.gp-m12 {
  margin: 12px;
}

.gp-m16 {
  margin: 16px;
}

.gp-m20 {
  margin: 20px;
}

.gp-m24 {
  margin: 24px;
}

.gp-m32 {
  margin: 32px;
}

.gp-m40 {
  margin-bottom: 40px;
}

.gp-mt4 {
  margin-top: 4px;
}

.gp-mt6 {
  margin-top: 6px;
}

.gp-mt8 {
  margin-top: 8px;
}

.gp-mt12 {
  margin-top: 12px;
}

.gp-mt16 {
  margin-top: 16px;
}

.gp-mt20 {
  margin-top: 20px;
}

.gp-mt24 {
  margin-top: 24px;
}

.gp-mt28 {
  margin-top: 28px;
}

.gp-mt32 {
  margin-top: 32px !important;
}

.gp-mt40 {
  margin-top: 40px;
}

.gp-mt60 {
  margin-top: 60px;
}

.gp-mt120 {
  margin-top: 120px !important;
}

.gp-mt-auto {
  margin-top: auto;
}

.gp-mb4 {
  margin-bottom: 4px;
}

.gp-mb8 {
  margin-bottom: 8px;
}

.gp-mb12 {
  margin-bottom: 12px;
}

.gp-mb16 {
  margin-bottom: 16px;
}

.gp-mb20 {
  margin-bottom: 20px;
}

.gp-mb24 {
  margin-bottom: 24px;
}

.gp-mb28 {
  margin-bottom: 28px;
}

.gp-mb32 {
  margin-bottom: 32px;
}

.gp-mb40 {
  margin-bottom: 40px;
}

.gp-mr4 {
  margin-right: 4px;
}

.gp-mr8 {
  margin-right: 8px;
}

.gp-mr12 {
  margin-right: 12px;
}

.gp-mr16 {
  margin-right: 16px;
}

.gp-mr20 {
  margin-right: 20px;
}

.gp-mr24 {
  margin-right: 24px;
}

.gp-mr28 {
  margin-right: 28px;
}

.gp-mr32 {
  margin-right: 32px;
}

.gp-mr40 {
  margin-right: 40px;
}

.gp-mr-auto {
  margin-right: auto;
}


.gp-ml4 {
  margin-left: 4px;
}

.gp-ml6 {
  margin-left: 6px;
}

.gp-ml8 {
  margin-left: 8px;
}

.gp-ml12 {
  margin-left: 12px !important;
}

.gp-ml16 {
  margin-left: 16px;
}

.gp-ml20 {
  margin-left: 20px;
}

.gp-ml24 {
  margin-left: 24px;
}

.gp-ml28 {
  margin-left: 28px;
}

.gp-ml32 {
  margin-left: 32px;
}

.gp-ml40 {
  margin-left: 40px;
}

.gp-ml-auto {
  margin-left: auto !important;
}