.view-request-container {
  height: calc(100vh);
  overflow: hidden;
  @media screen and (max-width: 768px) {
    overflow: auto;
  }

  .step-1-wrapper {
    display: flex;
    padding: 12px;
    background-color: #FFFF;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  .step-2-wrapper {
    background: #6926d9;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .step-2-contents {
      height: 95%;
      width: 98%;
      border-radius: 20px;
      background: #FFF;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow-x: scroll;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      overflow-y: scroll;
      overflow-x: hidden;

      .content-wrapper {
        width: 50%;
      }
    }

    .input-wrapper {
      margin-left: auto;
      width: 60%;
      margin-right: auto;
    }
  }

  .left-container {
    width: 40%;
    height: calc(100vh - 25px);

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    .bg-image {
      border-radius: 12px;
      max-height: 100%;
      background-position: 25% 50%;
    }

    .welcome-text-wrapper {
      padding-top: 30px;
      text-align: center;
    }
  }

  .left-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .right-container {
    margin-left: auto;
    margin-right: auto;

    .input-wrapper {
      height: calc(100vh - 318px);
    }

    .h_230 {
      height: calc(100vh - 230px) !important;
    }

    .h_87 {
      height: calc(86vh - 230px) !important;
    }

    .h_96 {
      height: calc(96vh - 230px) !important;
    }

    .tnc-para {
      max-width: 330px;
      color: #9C9AA5;
      text-align: center;
      font-family: Manrope;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .common-label {
    color: #26203B;
    font-family: Manrope;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .MuiInputBase-root {
    border-radius: 8px !important;
    padding: 6px 12px !important;
  }

  a {
    color: black;
    font-weight: 600;
  }
}

.logo-style {
  width: 200px !important;
  margin-left: 30px;
}

.font-Manrope .MuiInputBase-input {
  font-family: 'Manrope', sans-serif;
}

.MuiInputBase-input:focus {
  background-color: #fff !important;
}

.font-Manrope .MuiInputBase-input::placeholder {
  font-family: 'Manrope', sans-serif;
}

.plm::placeholder {
  font-family: 'Manrope';
}

.light-grey-color {
  color: #919191;
}

.supplier_not_listed {
  margin: 5px auto 30px auto;
  width: 400px;
}

.black-etxt {
  color: #26203B;
}

.head-cont {
  justify-content: space-between;
  width: 520px;
  padding-left: 0px;
}

.ml_10 {
  margin-left: -10px;
}

.clr_919191 {
  color: #919191 !important;
}

.footer_link:hover {
  color: #6926d9 !important;
}

.copyright {
  color: #919191 !important;
  text-align: center;
  font-size: 12px;
  position: absolute;
  bottom: 3%;
  left: 63%;
}

.lead-container {
  margin: 0 auto;
  padding: 0 70px 50px 70px;
  text-align: center;

  @media screen and (max-width: 768px) {
    column-gap: 116px;
    flex-direction: column;
    width: auto;
    text-align: left;
  }
}

.page-subtitle {
  width: 325px;
}

.title-cont {
  text-align: center;
  font-size: 42px;

  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
    font-size: 40px;
  }
}

.form-cont {
  background-color: white;

  @media screen and (max-width: 768px) {
    width: 340px;
  }
}

.registration-cont {
  padding: 25px 14px 17px 0px;
  border-bottom: 3px solid black;
  width: 235px;
}

.reg-wrapper {
  padding: 25px 14px 17px 0px;
  border-bottom: 3px solid #EBEAED;
  width: 235px;
}

.input-cont {
  padding: 16px 80px 20px 39px;
  row-gap: 30px;

  @media screen and (max-width: 768px) {
    padding: 12px;
  }
}

.mrt_10 {
  margin-top: 20px !important;
  margin-bottom: -10px !important;
}

.dealers-cont {
  width: 70%;
  margin: 0 auto;
}

.progress-loader {
  position: relative;
  top: 30px;
  left: 48%;
}

.cp {
  cursor: pointer;
}

.w-90 {
  width: 90% !important;
}

.br_1 {
  border: 1px solid #919191;
  border-radius: 6px;
}

.seller_logo:hover {
  border: 1px solid #6926d9;
  border-radius: 6px;
}

.selected-seller {
  border: 1px solid #6926d9;
  border-radius: 6px;
  background: #4BA4B70D;
}

.grid-main-cont {
  text-align: center !important;
  margin: 0 auto !important;
  display: flex !important;
  justify-content: center !important;
  flex-direction: row !important;
  overflow-x: scroll;
  height: 250px;
}

.MuiGrid-root > .MuiGrid-item {
  padding-left: 0px !important;
}
