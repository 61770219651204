.advSectionContainer {
    display: flex;
    width: 33%;
    flex-direction: column;
    align-items: center;
    text-align: center;
    @media screen and (max-width: 768px) {
        width: 100%;
        max-width: 302px;
        margin-top: 60px;
    }
}